import React, { useContext, useEffect, useState } from "react";
import CreateProjectModal from "./Components/CreateProjectModal";
import { Button, Card, Col, Checkbox, Divider, Form, Input, Modal, Radio, Result, Row, Steps, Tabs, Typography, Upload, theme, Alert, Space } from "antd";
import { AuthContext } from "../context/AuthContext";
import { ArrowLeftOutlined, DownOutlined, PlusOutlined, } from "@ant-design/icons";
import { ReactComponent as Check } from "../images/checkmark.svg";
import { ReactComponent as CheckIcon } from "../images/check-icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCompanyContext } from "../context/CompanyContext";
import { createProjectTitle } from "../functions";
import { useNotificationContext } from "../context/NotificationContext";
import Loader from "./Components/Loader";
import InformationsLegales from "./OnBoarding/InformationsLegales";
// import { AddLibrary } from '../App';

const { Text, Link } = Typography;


const ProfilePage = () => {
  const navigation = useNavigate();

  const auth = useContext(AuthContext);
  const { user, accessToken, updateUser } = auth
  const { companyData, setCompanyData } = useCompanyContext()
  const { setInfo } = useNotificationContext()

  const [currentStep, setCurrentStep] = useState(0);
  const [open, openModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(null);
  const [checked, setChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [mandatPath, setMandatPath] = useState(false);

  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [project, setProject] = useState({
    ...createProjectTitle()
  });
  const [alert, setAlert] = useState({ active: false, message: "", type: "" });
  const [signatureData, setSignatureData] = useState({
    signature: null,
  });
  const [iframeVisible, setIframeVisible] = useState(false);

  // Callback function to initialize the YouSign SDK
  const initializeYouSign = (signatureUrl) => {
    // Ensure that the Yousign class is available
    if (window.Yousign) {
      // Configure and initialize the YouSign SDK
      const yousign = new window.Yousign({
        signatureLink: signatureData.signature ?? signatureUrl,
        iframeContainerId: 'iframe-container',
        isSandbox: true, // Set this to true if it's a sandbox environment
      });
      return yousign
      // Set the YouSign instance in state
      // setYousignInstance(yousign);
    } else {
      // The YouSign SDK has not been loaded yet, you can handle this case as needed
      console.error('YouSign SDK has not been loaded.');
      setInfo({ message: "YouSign", description: "YouSign SDK has not been loaded." })
    }
  };

  useEffect(() => {
    let step = 0
    if (companyData) {
      setSignatureData({ signature: companyData.signature })

      if (companyData.validate || companyData.signatureStatus) {
        if (user?.isOnboarding) {
          navigation("/dashboard");
        } else {
          step = 2
        }
      } else {
        step = 1
      }
    }
    setCurrentStep(step)
  }, [companyData]);

  // useEffect(() => {
  // if (currentStep === 1 && companyData?.signature) {
  //   handleButtonClick(companyData.signature);
  // }
  // }, [currentStep])

  const handleButtonClick = (signatureUrl) => {
    // Set the state to true to make the iframe visible
    const yousign = initializeYouSign(signatureUrl);

    if (yousign) {
      // Open the YouSign iframe
      const iframe = document.getElementById('yousign-iframe');
      if (iframe) {
        iframe.style.width = '100%'; // Set the desired width
        iframe.style.height = '600px'; // Set the desired height
      }

      yousign.onStarted((data) => {
        // console.log(data);
      });
      yousign.onSuccess((data) => {
        // console.log(data);
        handleSignatureSubmit();
        setCurrentStep(2);
      });

      yousign.onError((data) => {
        console.log(data);
      });

      yousign.onPing((data) => {
        // console.log("Ping - The signature request is loaded");
        // console.log(data);
      });

      // Show the iframe
      setIframeVisible(true);

    } else {
      // The YouSign SDK has not been loaded yet, you can handle this case as needed
      console.error('YouSign SDK has not been loaded.');
    }
  };

  const handleCheck = e => {
    if (e.target.checked) {
      setShowError(false)
    }
    setChecked(e.target.checked)
  }

  const items = [
    {
      key: "1", label: "Texte", children: (
        <Form layout="vertical" size="large">
          <Form.Item label="Style de la signature" wrapperCol={24} colon={false}>
            <Input />
          </Form.Item>
          <Form.Item name={"type"}>
            <Radio.Group>
              <Radio value={1}>Noir</Radio>
              <Radio value={2}>Bleu</Radio>
              <Radio value={3}>Vert</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Email" wrapperCol={24} colon={false}><Input /></Form.Item>
          <div style={{ flex: 1 }}>
            <Result className="no-margin px-radius" style={{ paddingTop: 0, padding: 0 }} icon={null} subTitle={null}            >
              <span className="check-icon"><CheckIcon width={32} height={32} /></span>
              <Typography.Paragraph style={{ color: "#344054", borderBottom: "1px solid #EAECF0", paddingBottom: 8, marginBottom: 8, fontWeight: 600, }}>
                Signature par défaut
              </Typography.Paragraph>
              <Typography.Paragraph style={{ color: token.colorIcon, fontSize: 12 }}>
                Signature :
              </Typography.Paragraph>
              <div style={{ textAlign: "center" }}>
                <img width={260} src="images/sign.png" alt="" />
              </div>
            </Result>
          </div>
        </Form>
      ),
    },
    { key: "2", label: `Dessin`, children: `Content of Tab Pane 2`, },
    { key: "3", label: `Image`, children: `Content of Tab Pane 3`, },
  ];

  const handleSignatureSubmit = async () => {
    try {
      setLoading(2);
      const payload = new FormData();
      payload.append("signature", signatureData.signature);
      payload.append("signatureStatus", true);
      payload.append("companyId", companyData.id);
      payload.append(`userId`, user.id);
      // Send the data to the backend
      const config = {
        method: "POST",
        url: "/profile/signature",
        data: payload,
        headers: {
          "x-access-token": user.accessToken
        }
      };
      const response = await axios(config);
      const data = response.data;
      if (data.success) {
        setCompanyData({ ...companyData, signatureStatus: true })
        // setUpdatedUser({ ...user, ...data.user })
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleAlert = ({ active = false, message = "", type = "" }) => {
    setAlert({ active, message, type });
  };

  const handleDownloadContract = async () => {
    try {
      const config = {
        method: "GET",
        url: `/profile/contract?companyId=${companyData.id}`,
        headers: {
          "content-type": "multipart/form-data",
          "x-access-token": accessToken ?? user.accessToken,
        },
        responseType: 'arraybuffer'
      };
      setLoading(5)
      const response = await axios(config);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "Mandat Sience non signé";
      a.click();
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const validateMandate = async () => {
    try {
      const config = {
        method: "POST",
        url: "/profile/validate",
        data: {
          validate: true,
          userId: user.id,
          companyId: companyData.id
        },
        headers: {
          "content-type": "application/json",
          "x-access-token": accessToken ?? user.accessToken,
        },
      };
      setLoading(4);
      const response = await axios(config);
      if (response.data.success) {
        setUpdatedUser({ ...user, ...response.data.user });
        setCurrentStep(2)
      } else {
        toggleAlert({
          active: true,
          message: response.data.message,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectCeation = async () => {
    try {
      const config = {
        method: "POST",
        url: "/project/create",
        data: {
          ...project,
          userId: user.id,
          isOnboarding: companyData?.signatureStatus === true
        },
        headers: {
          "content-type": "application/json",
          "x-access-token": accessToken ?? user.accessToken,
        },
      };
      setLoading(3);
      const response = await axios(config);
      if (response.data.project) {
        const { project } = response.data;
        setUpdatedUser({ ...user, ...response.data.user });
        setProject(project);
        openModal(1);
      } else {
        toggleAlert({
          active: true,
          message: response.data.message,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return auth.loading ? <Loader /> :
    <div style={{ padding: 40, maxWidth: "80%", margin: "0 auto" }}>
      <Card className="card-shadow">
        <Steps
          size="small"
          current={currentStep}
          progressDot
          items={[
            { title: "Informations légales", description: "Renseignez vos informations", },
            { title: "Validation du mandat", description: "Autorisez Sience à faire les démarches", },
            // { title: "Signature électronique", description: "Mandatez Sience", },
            { title: "Terminé", description: "Tout est ok !", },
          ]}
        />
      </Card>
      <Card className="card-shadow">

        {currentStep === 0 ? <InformationsLegales setSignatureData={setSignatureData} setCurrentStep={setCurrentStep} setMandatPath={setMandatPath} /> : null}

        {currentStep === 1 ? (
          <>
            <Row style={{ alignItems: "center", marginBottom: 15 }}>
              <Col style={{ flex: 1 }}>
                <Typography.Title style={{ margin: 0, fontSize: 16 }} level={4}>
                  Validation du mandat
                  {/* Signature électronique du mandat Sience */}
                </Typography.Title>
                <Typography.Paragraph style={{ margin: 0, fontSize: 16 }} level={4}>
                  Autorisez Sience à faire les démarches à votre place
                </Typography.Paragraph>
              </Col>
              <Col xs={12} style={{ textAlign: "right" }}>
                <Space>
                  <Button
                    size="large"
                    htmlType="button"
                    loading={loading === 4}
                    disabled={loading === 4}
                    onClick={() => {
                      if (checked) {
                        validateMandate()
                      } else {
                        setShowError(true)
                      }
                    }}
                    type="primary"
                  >
                    Continuer
                    {/* Je le ferai plus tard */}
                  </Button>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Checkbox onChange={handleCheck}>Je valide le mandat autorisant Sience à garantir la rémunération pour le compte de l’entreprise.</Checkbox>
            {showError ? <Typography.Paragraph type="danger">Veuillez valider le mandat afin de passer à la prochaine étape.</Typography.Paragraph> : null}
            <Typography.Paragraph type="danger"><Button type="link" disabled={loading === 5} loading={loading === 5} onClick={handleDownloadContract} >Mandat Sience</Button></Typography.Paragraph>
            {/* <div id="iframe-container" style={{ display: iframeVisible ? 'block' : 'none' }}>
            </div> */}
          </>
        ) : null}

        <Modal
          className="createSign"
          width={680}
          open={open === 2}
          onCancel={() => openModal(false)}
          okText={"Enregistrer"}
          cancelText={"Annuler"}
          cancelButtonProps={{ block: true, size: "large" }}
          okButtonProps={{ block: true, size: "large" }}
        >
          <Tabs className="tabs-block" items={items} type="card" />
        </Modal>
        <CreateProjectModal
          isOpen={open === 1}
          onClose={() => openModal(false)}
          handleClick={() => {
            updateUser({ ...updatedUser })
            navigation("/dashboard")
          }}
          isOnBoard={true}
          projectId={project.id}
          title={project.title}
        />
        {currentStep === 2 ? (
          <Result
            title="Top, tout est ok !"
            subTitle={
              <>
                <p>Vous avez complété votre profil avec succès.</p>
                {/* {
                  companyData?.signatureStatus ? null : <Button size="large" onClick={() => setCurrentStep(1)}>
                    <ArrowLeftOutlined /> Retour
                  </Button>
                } */}
                <Button
                  size="large"
                  type="primary"
                  loading={loading === 3}
                  onClick={async () => {
                    if (project.id) {
                      openModal(1);
                    } else {
                      handleProjectCeation();
                    }
                  }}
                  style={{ marginLeft: 24 }}
                >
                  Créer mon premier projet
                </Button>
                <br />
                {alert.active ? (
                  <div style={{ width: "482px", margin: "20px auto", }}>
                    <Alert showIcon closable message={alert.message} type={alert.type} />
                  </div>
                ) : null}
              </>
            }
            icon={<Check width={100} />}
          />
        ) : null}
        <>
          {/* {currentStep === 2 ? (
                  <Form
                      name="step3Form"
                      form={form}
                      labelAlign="left"
                      colon={false}
                      size="large"
                      requiredMark={false}
                      labelCol={{
                          md: 6,
                          xs: 12,
                      }}
                      onFinish={() => setCurrentStep(3)}
                      onFinishFailed={() => setCurrentStep(3)}
                  >
                      <Row>
                          <Col>
                              <Typography.Title level={4} className="heading">
                                  Team Management
                              </Typography.Title>
                              <Typography.Paragraph>
                                  Add your Team members
                              </Typography.Paragraph>
                          </Col>
                          <Col
                              style={{
                                  marginLeft: "auto",
                              }}
                          >
                              <Button
                                  size="large"
                                  htmlType="button"
                                  onClick={() => setCurrentStep(3)}
                              >
                                  I’ll do later
                              </Button>
                              <Button
                                  size="large"
                                  type="primary"
                                  htmlType="submit"
                                  style={{ marginLeft: 15 }}
                              >
                                  Continue
                              </Button>
                          </Col>
                      </Row>
                      <Divider />
                      <Form.Item
                          label={
                              <div>
                                  <div>Invite team members </div>
                                  <small className="text-mute" style={{ whiteSpace: "wrap" }}>
                                      Get your projects up and running faster by inviting your
                                      team to collaborate.
                                  </small>
                              </div>
                          }
                      >
                          <Form.List name="emails">
                              {(fields, { add, remove }) => (
                                  <>
                                      {fields.map((field, index) => (
                                          <Row gutter={20}>
                                              <Col md={16}>
                                                  <Form.Item name={[field.name, "email"]}>
                                                      <Input prefix={<MailOutlined />} />
                                                  </Form.Item>
                                              </Col>
                                              <Col md={8}>
                                                  <Form.Item name={[field.name, "type"]}>
                                                      <Select>
                                                          <Select.Option value="readonly">
                                                              Read only
                                                          </Select.Option>
                                                          <Select.Option value="editable">
                                                              Editable
                                                          </Select.Option>
                                                      </Select>
                                                  </Form.Item>
                                              </Col>
                                          </Row>
                                      ))}
                                      <Button
                                          type="link"
                                          onClick={() => add()}
                                          icon={<PlusOutlined />}
                                      >
                                          Add another
                                      </Button>
                                      <Button
                                          type="primary"
                                          style={{ float: "right" }}
                                          onClick={() => add()}
                                          icon={<MailOutlined />}
                                      >
                                          Send invites
                                      </Button>
                                  </>
                              )}
                          </Form.List>
                      </Form.Item>
                      <Divider />
                      <Form.Item
                          name={"logo"}
                          label={
                              <>
                                  <div>
                                      <div>Team members </div>
                                      <small className="text-mute" style={{ whiteSpace: "wrap" }}>
                                          Manage your existing team and change roles/permissions.
                                      </small>
                                  </div>
                              </>
                          }
                      >
                          <Table
                              rowSelection={rowSelection}
                              dataSource={[
                                  {
                                      key: "1",
                                      id: "1",
                                      first_name: "Olivia",
                                      last_name: "Rhye",
                                      email: "olivia@company.cm",
                                      role: "Admin",
                                      avatar: "https://xsgames.co/randomusers/avatar.php?g=pixel",
                                  },
                                  {
                                      key: "2",
                                      id: "2",
                                      first_name: "Phoenix",
                                      last_name: "Baker",
                                      role: "Read-only",
                                      email: "phoenix@company.cm",
                                      avatar: "https://xsgames.co/randomusers/avatar.php?g=pixel",
                                  },
                              ]}
                              columns={[
                                  {
                                      title: "Name",
                                      key: "first_name",
                                      dataIndex: "first_name",
                                      render: (value, row) => {
                                          return (
                                              <Card.Meta
                                                  className="table-meta-cell"
                                                  avatar={<Avatar size={40} src={row.avatar} />}
                                                  title={<span>{value + " " + row.last_name}</span>}
                                                  description={row.email}
                                              />
                                          );
                                      },
                                  },
                                  {
                                      key: "role",
                                      dataIndex: "role",
                                      title: "Role",
                                  },
                                  {
                                      key: "id",
                                      dataIndex: "id",
                                      title: "",
                                      align: "right",
                                      render: (value) => {
                                          return (
                                              <>
                                                  <Button
                                                      style={{ color: "#444" }}
                                                      size="small"
                                                      type="link"
                                                  >
                                                      Delete
                                                  </Button>
                                                  <Button size="small" type="link">
                                                      Edit
                                                  </Button>
                                              </>
                                          );
                                      },
                                  },
                              ]}
                          />
                      </Form.Item>
                  </Form>
              ) : null} */}
        </>
      </Card>
    </div>

};

export default ProfilePage;
